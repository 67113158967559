:root {
  --primary-color: #0078d6;
  --primary-color-rgb: 0, 120, 214;
  --warning-color: #ffb801;
  --warning-color-rgb: 255, 184, 1;
  --error-color: #c00000;
  --error-color-rgb: 192, 0, 0;
  --success-color: #19b500;
  --success-color-rgb: 25, 181, 0;
  --foreground-dark-color: #212529;
  --foreground-dark-color-rgb: 33, 37, 41;
  --disabled-color: rgba(var(--foreground-dark-color-rgb), 0.5);
  --dimmed-foreground-color: rgba(var(--foreground-dark-color-rgb), 0.6);
  --foreground-light-color: #ffffff;
  --foreground-light-rgb: 255, 255, 255;
  --dimmed-background-color: #f3f3f3;
}

#root {
  display: flex;
  flex-direction: column;
}

.primary-text, .primary-text-bold, .primary-text-large, .primary-text-large-bold {
  color: var(--foreground-dark-color);
}

.primary-text, .primary-text-bold, .warning-text, .error-text, .success-text {
  font-size: 18px;
  line-height: 24px;
}

.primary-text-large, .primary-text-large-bold {
  font-size: 24px;
  line-height: 32px;
}

.primary-text-bold, .primary-text-large-bold {
  font-weight: 700;
}

.warning-text {
  color: var(--warning-color);
}

.error-text {
  color: var(--error-color);
}

.success-text {
  color: var(--success-color);
}

.cursor-pointer {
  cursor: pointer;
}

.default-input {
  border: 1px solid var(--disabled-color);
  border-radius: 5px;
  font-size: 18px;
  line-height: 24px;
}

.default-checkbox > .form-check-input {
  border: 1px solid var(--primary-color);
  width: 24px;
  height: 24px;
}

.default-checkbox > .form-check-label {
  color: var(--primary-color);
  font-weight: 500;
  font-size: 16px;
  margin-left: 12px;
  vertical-align: sub;
}

.login-user-button {
  width: 52px;
  height: 52px;
  border-radius: 50% !important;
}

.login-user-button > img {
  margin: 0 !important;
}

.button > svg.bi {
  margin-right: 12px;
}

.dialog-header {
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  margin-top: 16px;
}

.dialog-subheader {
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  margin-top: 8px;
}

.dialog-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1080;
}

.dialog-back-button {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1080;
}

.dialog-container .modal-content {
  border: 1px solid var(--primary-color);
}

.max-width-unset {
  max-width: unset;
}

div.disabled {
  opacity: 0.4;
  pointer-events: none;
}