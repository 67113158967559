.landing-main-title {
  font-weight: 700;
  font-size: 48px;
  text-align: center;
  margin-bottom: 12px;
}

.landing-main-text {
  text-align: center;
  margin-bottom: 24px;
}

.landing-secondary-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
}

.landing-main-image {
    border: 20px solid rgba(0, 120, 214, 0.5);
    box-shadow: 4px 4px 10px rgba(0, 120, 214, 0.35);
}

.landing-buy-server-button {
  margin-top: 24px;
  margin-bottom: 56px;
}

.landing-about-block {
  margin-top: 56px;
  margin-bottom: 30px;
}

.landing-buy-server-outer {
  margin-bottom: 56px;
}
