@import url('https://fonts.googleapis.com/css?family=Open+Sans');

html, body, #root {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;

    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    
    background-color: var(--foreground-light-color);
    background-repeat: no-repeat;
    color: var(--foreground-dark-color);
}