.history-container {
    padding-right: 0;
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
}

.history-entries-container {
    padding: 12px;
    min-height: 100px;
}

.history-card {
    min-height: 100px;
}