.machine-entry-container {
    border: 1px solid var(--disabled-color);
    border-radius: 5px;
    padding: 12px 24px;
}

.machine-entry-container-selected {
    background: var(--dimmed-background-color);
    border: 1px solid var(--primary-color);
}

.machine-entry-name {
    font-size: 22px;
    line-height: 32px;
}