.language-picker-container {
    display: flex;
    align-items: center;
}

.language-picker-icon {
    margin-right: 4px;
}

.language-picker-label {
    margin-right: 12px;
}

.language-picker {
    background-color: transparent;
    border: 1px solid var(--foreground-light-color);
    border-radius: 5px;
    width: 100%;
    flex-grow: 1;
}

.language-picker-toggle {
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    padding: 12px;
}

.language-picker-toggle::after {
    margin-top: 8px;
}

.language-picker-menu {
    background: var(--foreground-light-color);
    border: 1px solid var(--primary-color);
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.language-picker-menu-item {
    background-color: transparent;
}

.language-picker-menu-item:hover {  
    background: #F3F3F3;
    border: 1px solid rgba(33, 37, 41, 0.2);
    box-sizing: border-box;
    border-radius: 5px;
}

.language-picker-menu-item:focus {
    color: 1px solid rgba(33, 37, 41, 0.2);
}

.language-picker-menu-item.active {
    color: var(--primary-color);
    background-color: transparent;
}