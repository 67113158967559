.login-modal-title {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}

.btn-microsoft-sign-in, .btn-microsoft-sign-in:hover {
    font-size: 16px;
    font-weight: 600;
    color: #5e5e5e;
    background: #ffffff;
    border: solid 1px #8c8c8c;
    border-radius: 0px;
    width: 100%;
    box-shadow: none;
}

.btn-google-sign-in, .btn-google-sign-in:hover {
    font-size: 18px;
    font-weight: 500;
    color: #212529;
    background: #E3E2E2;
    border-radius: 5px;
    width: 100%;
    box-shadow: none;
}

.btn-facebook-sign-in, .btn-facebook-sign-in:hover {
    font-size: 18px;
    font-weight: 500;
    color: #FFFFFF;
    background: #4267B2;
    border-radius: 5px;
    width: 100%;
    box-shadow: none;
}

.btn-microsoft-sign-in:hover, .btn-google-sign-in:hover, .btn-facebook-sign-in:hover {
    opacity: 0.75;
}

.microsoft-sign-in-icon, .google-sign-in-icon, .facebook-sign-in-icon {
    margin-right: 12px;
}

.microsoft-sign-in-text, .google-sign-in-text, .facebook-sign-in-text {
    vertical-align: middle;
}

.btn-facebook-sign-in, .btn-google-sign-in, .btn-facebook-sign-in {
    width: 100%;
}