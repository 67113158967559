.ftp-info-space {
    padding: 12px 12px 24px 12px;
    height: 100%;
}

.ftp-info-users {
    padding: 12px 12px 8px 12px;
    height: 100%;
}

.ftp-info-title {
    font-size: 24px;
    font-weight: 700;
    color: var(--foreground-dark-color);
}

.ftp-info-title-used, .ftp-info-title-free {
    font-size: 18px;
    font-weight: 700;
}

.ftp-info-title-used {
    color: var(--error-color);
}

.ftp-info-title-free {
    color: var(--primary-color);
}

.ftp-info-space-progress {
    height: 32px;
    background-color: var(--primary-color);
}

.ftp-info-space-progress > .progress-bar {
    background-color: var(--error-color);
    border-radius: 5px;
}

.ftp-info-space-refresh-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.ftp-info-placeholder-container {
    padding: 32px 12px;
}