.server-info-container {
    padding: 32px 12px;
    display: flex;
    justify-content: space-around;
}

.server-info-name {
    font-size: 24px;
    font-weight: 700;
    color: var(--foreground-dark-color)
}

.server-info-name-ip {
    flex-grow: 1;
    justify-content: center;
}

.server-info-period {
    flex-grow: 0;
}

.server-info-period-range {
    text-align: start;
    color: var(--success-color);
    font-size: 18px;
}