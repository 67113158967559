.checkout-main-title {
  font-weight: 700;
  font-size: 32px;
}

.checkout-main-text, .checkout-section-heading {
  font-weight: 500;
  font-size: 24px;
}

.checkout-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.checkout.btn-microsoft-sign-in, .checkout.btn-google-sign-in, .checkout.btn-facebook-sign-in {
    max-width: unset;
}