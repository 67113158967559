.header {
    margin-top: 14px;
    margin-bottom: 56px;
}

.header-title {
    font-weight: 700;
    color: #0078D6;
    font-size: 24px;
    line-height: 33px;
    vertical-align: middle;
    cursor: pointer;
}

.header-login {
    width: 100%;
}

.dashboard-search-box {
    height: 52px;
    max-width: 400px;
    margin-right: 24px;
}

.dashboard-button {
    margin-right: 24px;
    height: 52px;
}

.logout-popup {
    border: 1px solid var(--primary-color);
    background: #FFFFFF;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    width: 100%;
}

.logout-popup > .popover-body {
    padding: 32px 24px;
}

.logout-title {
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
}

.logout-email {
    color: var(--primary-color);
    font-size: 18px;
    line-height: 24px;
}

.logout-button {
    width: 100%;
}