.expiration-label {
    text-align: center;
}

.expiration-label-active {
    color: var(--success-color)
}

.expiration-label-expiring-warning {
    color: var(--warning-color)
}

.expiration-label-expiring-danger {
    color: var(--error-color)
}

.expiration-label-stopped {
    color: var(--foreground-dark-color)
}