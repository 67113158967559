.dashboard-title {
    font-size: 24px;
    color: var(--primary-color);
    font-weight: 700;
    line-height: 32px;
}

.dashboard-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.dashboard-content-container {
    flex-grow: 1;
}

.vertical-nav-link {
    height: 56px;
    display: flex;
    align-items: center;
    color: var(--foreground-dark-color);
    font-size: 18px;
}

.vertical-nav-link.active {
    background: rgba(var(--primary-color-rgb), 0.1);
    border-right: solid 8px var(--primary-color);
    border-radius: 5px;
}