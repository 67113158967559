footer {
  background-color: var(--primary-color);
  color: var(--foreground-light-color);
  padding-top: 14px;
  padding-bottom: 14px;
}

.footer-links > a {
  text-decoration: none;
  color: var(--foreground-light-color);
  text-align: center;
}