.dashboard-home-container {
    padding-right: 0;
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
}

.dashboard-machine-list-container {
    flex-grow: 1;
    padding: 12px;
}

.dashboard-machine-list-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
}