.button {
    max-width: 300px;
    height: 52px;
    font-size: 18px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.button-default {
    background: var(--primary-color);
    color: var(--foreground-light-color);
    box-shadow: 4px 4px 10px rgba(var(--primary-color-rgb), 0.35);
    border: 0px;
}

.button-disabled {
    background: var(--disabled-color);
    color: var(--foreground-light-color);
    box-shadow: 4px 4px 10px var(--disabled-color);
    border: 0px;
}

.button-clear {
    background: transparent;
    color: var(--foreground-dark-color);
    border: 1px solid var(--foreground-dark-color);
}