.history-entry-container {
    border: 1px solid var(--disabled-color);
    border-radius: 5px;
    padding: 12px 24px;
}

.history-entry-title {
    color: var(--disabled-color);
    font-size: 24px;
    line-height: 32px;
}

.history-entry-text {
    font-size: 18px;
    line-height: 24px;
}

.history-entry-date {
    color: var(--disabled-color);
    font-size: 18px;
    line-height: 24px;
}