.icon-button {
    border: 0;
    /*padding: 0.375rem;*/
    padding: 0rem;
}

.icon-button > svg {
    margin: 0.375rem;
}

.icon-button-clear {
    background-color: transparent;
}

.icon-button-clear:hover, .icon-button-clear:focus, .icon-button-clear:active {
    background-color: transparent;
}