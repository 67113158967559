.landing-configuration-container {
  padding: 24px;
  height: 100%;
}

.landing-configuration-title, .landing-summary-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  margin-bottom: 24px;
}

.landing-summary-container {
  padding: 24px;
}

.summary-info-entry-value {
  color: var(--dimmed-foreground-color);
  text-align: end;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.summary-divider {
  margin-top: 4px;
  margin-bottom: 8px;
}

.button-continue {
  margin-top: 24px;
}

.button-continue-arrow {
  margin-left: 12px;
}
