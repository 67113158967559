.about-container {
    padding-right: 0;
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
}

.about-card {
    flex-grow: 1;
    padding: 12px;
}

.about-title {
    font-size: 24px;
    line-height: 32px;
}

.about-text {
    font-size: 18px;
    line-height: 24px;
}

.share-button-container {
    max-width: 300px;
}